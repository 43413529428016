"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "../index.scss";

// carousel
import "./carousel";

// SmoothScroll inicializace
import SmoothScroll from "smooth-scroll";
new SmoothScroll('a[href*="#"]', {
	header: "header",
	speed: 300,
	speedAsDuration: true,
});

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}


// galerie SimpleLightbox - BEGIN
// https://simplelightbox.com
/*
import SimpleLightbox from "simplelightbox";
import "simplelightbox/src/simple-lightbox.scss";
window.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll("*[data-gallery]").forEach((gallery) => {
		new SimpleLightbox("#" + gallery.getAttribute("id") + " a", {
			/* options 
		});
	});
});
*/
// galerie SimpleLightbox - BEGIN

import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
document.addEventListener("DOMContentLoaded", function () {
	const lightbox = GLightbox({
		
		touchNavigation: true,
		loop: true,
		autoplayVideos: true,
	});
});

// clickToggleClass - BEGIN
window.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll("*[data-click-toggle-class]").forEach((elm) => {
		elm.addEventListener("click", (event) => {
			event.preventDefault();
			const classes = event.target.dataset.clickToggleClass.split("|");
			const targets = event.target.dataset.clickToggleTarget
				? [...document.querySelectorAll(event.target.dataset.clickToggleTarget)]
				: [event.target];
			const toggledClases = targets[0].classList.contains(classes[0])
				? {
						remove: classes[0],
						add: classes[1],
				  }
				: {
						remove: classes[1],
						add: classes[0],
				  };
			targets.forEach((elm) => {
				elm.classList.remove(toggledClases.remove);
				elm.classList.add(toggledClases.add);
			});
		});
	});
});
window.addEventListener("DOMContentLoaded", () => {
	const menuToggle = document.getElementById("toggleMenu");
	menuToggle.addEventListener("click", (event) => {
		if (menuToggle.classList.contains("open")) {
			menuToggle.classList.remove("open");
		} else {
			menuToggle.classList.add("open");
		}
	});
	
	
});
// clickToggleClass - END


//modal fucntion
window.addEventListener("DOMContentLoaded", () => {
	const modal = document.getElementById("modal");
	if (typeof modal != "undefined" && modal != null) {
		// ZAVRENI MODALU
		const modalClose = document.getElementById("modalClose");
		modalClose.addEventListener("click", (event) => {
			event.preventDefault();
			modal.classList.add("modal__hidden");
		});

		//OTEVRENI MODALU
		const modalBody = document.getElementById("modalBody");

		document.querySelectorAll("*[data-open-modal]").forEach((elm) => {
			elm.addEventListener("click", (event) => {
				event.preventDefault();
				modal.classList.remove("modal__hidden");
				modalBody.scrollIntoView();
			});
		});
	}
});

//scroll na modal
window.addEventListener("DOMContentLoaded", () => {
	const modal = document.getElementById("modal");
	if (typeof modal != "undefined" && modal != null) {
		if (!modal.classList.contains("modal__hidden")) {
			document.getElementById("modalBody").scrollIntoView();
		}
	}
});

//SCROLL FUNCTIONS
var scrl = 0;
var scrlA = 0;
var scrlB = 0;
var scrlMax = 450;
var scrlState = 0;

//scrlState - 0 - menu je vidět a je bez pozadí
//scrlState - 1 - menu je vidět a je tam pozadí
//scrlState - 2 - menu není vidět a je tam pozadí

$(window).scroll(function (event) {
  scrlA = $(window).scrollTop();
  //console.log("scrlA " + scrlA);
  if (scrlB == scrlA && scrlA == 0) {
    //console.log("nehejbe se a je nahoře");
    //menu je vidět a je bez pozadí
    if (scrlState != 0) {
      $("#top_menu").removeClass("topMenu__scrolled");
      $("#top_menu").removeClass("topMenu__topHidden");
      scrlState = 0;
    }
  } else if (scrlB > scrlA && scrlA > 0) {
    //console.log("jede nahoru  a není nahoře");
    //menu je vidět a je tam pozadí
    if (scrlState != 1) {
      $("#top_menu").addClass("topMenu__scrolled");
      $("#top_menu").removeClass("topMenu__topHidden");
      scrlState = 1;
    }
  } else if (scrlB < scrlA && scrlA >= scrlMax) {
    //console.log("jede dolu a není nahoře a je výš než max");
    //menu není vidět
    if (scrlState != 2) {
      $("#top_menu").addClass("topMenu__scrolled");
      $("#top_menu").addClass("topMenu__topHidden");
      scrlState = 2;
    }
  } else if (scrlB < scrlA && scrlA < scrlMax) {
    //console.log("jede dolu a není nahoře a je níž než max");
    //menu je vidět a je tam pozadí
    if (scrlState != 1) {
      $("#top_menu").addClass("topMenu__scrolled");
      $("#top_menu").removeClass("topMenu__topHidden");
      scrlState = 1;
    }
  } else if (scrlA == 0) {
    console.log("je nahoře");
    //menu je vidět a je bez pozadí
    if (scrlState != 0) {
      $("#top_menu").removeClass("topMenu__scrolled");
      $("#top_menu").removeClass("topMenu__topHidden");
      scrlState = 0;
    }
  }
  scrlB = $(window).scrollTop();
  /*
  if (scrlA == 0 && scrl != 0) {
    $(".headerBack").removeClass("down");
    $(".header").addClass("headerHide");
    scrl = 0;
    //console.log('vypnuto off');
  }
  if (scrlA > 0 && scrl == 0) {
    $(".headerBack").addClass("down");
    scrl = 1;
    //console.log('zapnuto off');
  }
  */

  //console.log($(window).scrollTop());
  // Do something
});